//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-756:_280,_7232,_4088,_8032,_3936,_2508,_8956,_9592;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-756')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-756', "_280,_7232,_4088,_8032,_3936,_2508,_8956,_9592");
        }
      }catch (ex) {
        console.error(ex);
      }

        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('li', { 'className': 'item' }, _createElement('div', { 'className': 'card-wrapper product-card-wrapper underline-links-hover' }, _createElement('a', {
                    'href': this.url,
                    'className': 'card card--standard card--media'
                }, _createElement('div', { 'className': 'card__inner gradient' }, _createElement('div', { 'className': 'card__media' }, _createElement('div', { 'className': 'media--transparent media--hover-effect' }, _createElement('img', {
                    'className': 'motion-reduce',
                    'data-sizes': 'auto',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }))), _createElement('div', { 'className': 'card__badge top left' }, this.on_sale ? _createElement('span', {
                    'className': 'badge badge--bottom-left color-accent-1',
                    'key': '562'
                }, 'Sale') : null)), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__information' }, _createElement('h3', { 'className': 'card__heading h5' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'full-unstyled-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'card-information' }, _createElement('span', { 'className': 'visually-hidden' }, 'Vendor:'), _createElement('div', mergeProps({ 'className': 'caption-with-letter-spacing light' }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('span', { 'className': 'caption-large light' }), _createElement('div', { 'className': 'price' + (this.on_sale ? ' price--on-sale' : '') }, _createElement('div', { 'className': 'price__container' }, !this.on_sale ? _createElement('div', {
                    'className': 'price__regular',
                    'key': '1349'
                }, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price))) : null, this.on_sale ? _createElement('div', {
                    'className': 'price__sale',
                    'key': '1540'
                }, _createElement('span', { 'className': 'price-item price-item--last price-item--sale' }, '\n                    ', this.formatPrice(this.price), '\n                  '), _createElement('span', { 'className': 'price-item price-item--regular' }, '\n                     ', _createElement('del', {}, this.formatPrice(this.compare_at_price)))) : null))), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '20050'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null]), this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '2425'
                }) : null))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'grid cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []